import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './Countdown.css';
import stedwayLogo from './images/stedway.jpeg';

class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    }

    componentWillMount(){
        this.getTimeUntil(this.props.deadline);
    }

    componentDidMount() {
        setInterval( () => this.getTimeUntil(this.props.deadline), 1000);
    }

    getTimeUntil(deadline) {
        const time = Date.parse(deadline) - Date.parse(new Date());
        const seconds = Math.floor((time/1000)%60);
        const minutes = Math.floor((time/1000/60)%60);
        const hours = Math.floor(time/(1000*60*60) % 24);
        const days = Math.floor(time/(1000*60*60*24));
        this.setState({days: days, hours: hours, minutes: minutes, seconds: seconds});
    }
    render() {
        return (
            <div className='timer'>
                <h5>Stedway will be launched in:</h5>
                <span>{this.state.days} days</span>
                <span>{this.state.hours} hours</span>
                <span>{this.state.minutes} minutes</span>
                <span>{this.state.seconds} seconds</span>
                <img src={stedwayLogo} alt="Stedway Logo"/>
            </div>
        )
    }
}

export default Countdown;