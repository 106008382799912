import React, {Component} from 'react';
import Countdown from './Countdown.es6';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        deadline: 'August 31, 2021',
        newDeadline: ''
    }
  }
  updateDeadline = () => {
    this.setState({deadline: this.state.newDeadline});
  } 
  render() {
    return(
      <div>
          <Countdown deadline={this.state.deadline}/>
      </div>
    )  
  }
}

export default App;
